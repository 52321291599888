import { render, staticRenderFns } from "./filter-list-indexPage.vue?vue&type=template&id=97ba3efe&"
import script from "./filter-list-indexPage.vue?vue&type=script&lang=js&"
export * from "./filter-list-indexPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RangePrice2: require('/home/d/didisher2/auto-expert-moskva.rf/www/components/range/range-price2.vue').default,RangeYear2: require('/home/d/didisher2/auto-expert-moskva.rf/www/components/range/range-year2.vue').default})
